import React, { ChangeEvent, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import {
  doc,
  setDoc,
} from 'firebase/firestore';
import { useFirestore, useFunctions } from 'reactfire';

import { useSuperadmin } from '../user';
import {
  GetUserByEmailRequest,
  GetUserByEmailResponse,
  SetCustomUserClaimsRequest,
} from '../common/functionsTypes';
import Loading from '../Loading';
import { defaultMaxGroups } from '../common/firestoreTypes';
import { showError } from '../modal';
import UserGroupList from '../UserGroupList';

export default function EditUser() {
  const functions = useFunctions();
  const firestore = useFirestore();
  const isSuperadmin = useSuperadmin();
  const [user, setUser] = useState<GetUserByEmailResponse | undefined>();
  const { email } = useParams();

  useEffect(() => {
    if (!isSuperadmin || !email) {
      return;
    }
    const getUser = async () => {
      const getUserByEmail = httpsCallable<GetUserByEmailRequest, GetUserByEmailResponse>(
        functions,
        'getUserByEmail',
      );
      try {
        const response = await getUserByEmail({ email });
        setUser(response.data);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('Error getting user data:', error);
        showError(error.message);
      }
    };
    getUser();
  }, [functions, isSuperadmin, email]);

  if (!isSuperadmin) {
    return null;
  }

  if (!user) {
    return (
      <Loading />
    );
  }

  const { userInfo, userState } = user;
  const { uid } = userInfo;

  const onChangeMaxGroups = async (event: ChangeEvent<HTMLInputElement>) => {
    const maxGroups = Number(event.target.value);
    // if (isNaN(maxGroups)) {
    //   return;
    // }
    // Why didn't I sync the UserState Firestore document here instead of
    // having getUserByEmail return it, so I could just update the document and
    // have the React state update automatically? I don't know
    setUser({
      ...user,
      userState: {
        ...userState,
        maxGroups,
      },
    });
    await setDoc(
      doc(firestore, `userStates/${userInfo.uid}`),
      { maxGroups },
      { merge: true },
    );
  };

  const updateClaim = async (claimName: string, enable: boolean) => {
    const claims = { ...userInfo.claims };
    if (enable) {
      // Presence or absence of the custom claim indicates superadmin or
      // invited status. Using 1 instead of true keeps the auth token smaller.
      claims[claimName] = 1;
    } else {
      delete claims[claimName];
    }
    setUser({
      ...user,
      userInfo: {
        ...userInfo,
        claims,
      },
    });

    const setCustomUserClaims = httpsCallable<SetCustomUserClaimsRequest>(
      functions,
      'setCustomUserClaims',
    );
    try {
      await setCustomUserClaims({ uid, claims });
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('Error setting custom claims:', error);
      showError(error.message);
    }
  };

  return (
    <div>
      <h2>
        <img
          src={userInfo.photoURL || ''}
          alt="user avatar"
          className="rounded-full w-8 h-8 inline"
        />
        &ensp;
        { userInfo.displayName }
      </h2>
      <div>
        <b>User ID:</b> { uid }
      </div>
      <div>
        <b>email:</b> { userInfo.email }
      </div>
      <div>
        <b>phone:</b> { userInfo.phoneNumber }
      </div>
      <br />
      <div>
        <b>Max groups (stored):</b>
        &ensp;
        <input
          type="number"
          step={1}
          min={1}
          value={userState.maxGroups || 0}
          onChange={onChangeMaxGroups}
        />
        &ensp;
        Effective max: {Math.max(userState.maxGroups || 0, defaultMaxGroups)}
      </div>
      <div>
        <input
          type="checkbox"
          checked={!userInfo.claims.invited}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            updateClaim('invited', !event.target.checked);
          }}
        />
        &ensp;Waitlisted
      </div>
      <div>
        <input
          type="checkbox"
          checked={Boolean(userInfo.claims.super)}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            updateClaim('super', event.target.checked);
          }}
        />
        &ensp;Superadmin
      </div>
      <br />
      <div>
        <b>Groups:</b>
        <UserGroupList uid={userInfo.uid} showHeader={false} />
      </div>
    </div>
  );
}
