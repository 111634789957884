import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { User } from 'firebase/auth';
import {
  doc,
  DocumentReference,
  setDoc,
  deleteDoc,
} from 'firebase/firestore';
import {
  useFirestore,
  useFirestoreDoc,
} from 'reactfire';

import { defaultMaxFreqWeeks, Group } from './common/firestoreTypes';
import Loading from './Loading';
import UserInfo from './UserInfo';
import MeetingList from './MeetingList';
import FirestoreInput from './FirestoreInput';
import { showConfirmation, showMessage } from './modal';
import NumberSelect from './NumberSelect';

type GroupSettingsProps = {
  user: User,
};

export default function GroupSettings({
  user,
}: GroupSettingsProps) {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const firestore = useFirestore();
  const groupRef = doc(firestore, `groups/${groupId}`) as DocumentReference<Group>;
  const { data, error, status } = useFirestoreDoc(groupRef);
  const group = data?.data();

  if (status === 'error') {
    return (
      <div>
        <h3>Error</h3>
        {error?.message }
      </div>
    );
  }
  if (status === 'loading' || !group) {
    return <Loading />;
  }

  const maxFreqWeeks = group.maxFreqWeeks || defaultMaxFreqWeeks;

  const deleteGroup = async () => {
    await showConfirmation((
      <div>
        <h3>⚠️ Delete group permanently?</h3>
        This cannot be undone. Consider disabling the group instead.
      </div>
    ));
    navigate('/');
    await deleteDoc(groupRef);
    showMessage('Group deleted');
  };

  return (
    <div>
      { !group.defaultLocation && (
        <p>
          &nbsp;⚠️ You should add a default location below! It can be a physical location
          or a URL for an online video chat.
        </p>
      )}
      <div>
        Name:&nbsp;
        <FirestoreInput
          documentRef={groupRef}
          valuePath="name"
        />
      </div>
      <div>
        Default location:&nbsp;
        <FirestoreInput
          documentRef={groupRef}
          valuePath="defaultLocation"
        />
        &nbsp;
        <span title="This can be a physical location or a URL for an online video chat.">
          ⓘ
        </span>
      </div>
      <div>
        Status:&nbsp;
        { group.status !== 'pending' && (
          <input
            type="checkbox"
            checked={group.status === 'active'}
            onChange={(event) => {
              setDoc(
                groupRef,
                { status: event.target.checked ? 'active' : 'inactive' },
                { merge: true },
              );
            }}
          />
        )}
        &nbsp;{ group.status }
      </div>
      <div>
        Max frequency: Once every&nbsp;
        <NumberSelect
          value={maxFreqWeeks}
          setValue={(value) => {
            setDoc(groupRef, { maxFreqWeeks: value }, { merge: true });
          }}
          max={10}
          values={[13, 26, 52]}
        />
        &nbsp;weeks&nbsp;
        <span title={`Friend Scheduler will not schedule you with this friend more often than this.
It is likely to be less often due to other constraints.`}>
          ⓘ
        </span>
      </div>
      <br />
      <h2>Members</h2>
      { group.members.map((member) => (
        <div key={('uid' in member ? member.uid : member.email)}>
          <UserInfo user={member} />
        </div>
      )) }
      <br />
      <MeetingList groupId={groupId} />
      <br />
      <br />
      <button
        type="button"
        className="bg-red-500 hover:bg-red-700"
        onClick={deleteGroup}
      >
        Delete group
      </button>
    </div>
  );
}
